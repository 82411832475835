import React from "react";
import ProductTemplate from "./product";
import { FiArrowRightCircle } from "react-icons/fi";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item} counter={4}>
            {/** ¿Qué es? */}
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="grid grid-cols-1">
                    <Text className="text-justify">
                        Et presentem el llibre de les 500 preguntes més repetides als exàmens de
                        policia local
                        <br />
                        <br />
                        El llibre de les 500 preguntes més repetides als exàmens de policia local és
                        un recopilatori preguntes oficials que han aparegut en els exàmens de les
                        convocatòries d'anys anteriors.
                        <br />
                        <br />
                        Un complement perfecte per profunditzar en el teu procés d'estudi; una
                        manera diferent d'aprendre.
                        <br />
                        <br />
                        Totes les preguntes contenen la resposta correcta i en moltes d'elles hem
                        ampliat informació.
                        <br />
                        <br />
                        Trobaràs tots els temes de les bases.
                        <br />
                        <br />
                        A més amb la compra del llibre t'habilitem de manera TOTALMENT GRATUÏTA el
                        mòdul de les 500 preguntes en format test per practicar el que has estudiat
                        i posar-te a prova!
                        <br />
                        <br />
                        Un mòdul que podràs fer servir a través de l'aplicació iGuB a qualsevol
                        moment i allà on vulguis.
                        <br />
                        <br />
                        Vols tenir una bona base de temari? Fes-te amb aquest llibre únic i no et
                        quedis enrere!
                        <br />
                        <br />
                        Al llibre trobaràs 20 temes que a continuació us detallem:
                        <br />
                        <ul>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Constitució i Organització Territorial
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Drets i Deures Fonamentals
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Estatut d'Autonomia de Catalunya
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                El Municipi
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Europa. Declaració Universal dels Drets Humans.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Estatut Bàsic de l'Empleat Públic
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                El Procediment Administratiu
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei Orgànica 2/1986 de Forces i Cossos de Seguretat
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei 16/1991 de les Policies Locals
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei 4/2003 d'Ordenació del Sistema de Seguretat Pública de
                                Catalunya
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Deontologia Policial. Codi d'Ètica de la Policia de Catalunya
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei 17/2015 d'Igualtat efectiva de dones i homes. Violència de
                                gènere.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Detenció i Habeas Corpus
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei Orgànica 4/2015 de Protecció de Seguretat Ciutadana
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Accidents de trànsit i circulació
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Codi Penal
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei 19/2014 de transparència, accés a la informació i bon govern
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei 11/2014 per a garantir els drets de lesbianes, gais, bisexuals,
                                transgèneres i intersexuals i per a eradicar l'homofòbia, la bifòbia
                                i la transfòbia.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Llei Orgànica 5/2000, de 12 de gener, reguladora de la
                                responsabilitat penal dels menors
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Ordenances Municipals
                            </li>
                        </ul>
                    </Text>
                </div>
            </div>

            {/** Preview dle libro */}
            {/*<div className="container lg:px-0 py-20">
                <Title>Preview del libro</Title>
                <Text className="text-justify">
                    A continuació us ensenyem el primer capítol del llibre.
                </Text>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/22/guia-rapida-mossos-2021.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    ></embed>
                </div>
            </div>*/}

            {/** Más imágenes */}
            {/*<div className="container lg:px-0 py-20">
                <Title>Imágenes del libro</Title>
                <div className="mt-10 grid grid-cols2 md:grid-cols-3 gap-10">
                    <Image
                        filename="products/22/image1"
                        className="rounded shadow"
                        alt="método imagen 1"
                    />
                    <Image
                        filename="products/22/image2"
                        className="rounded shadow"
                        alt="método imagen 2"
                    />
                    <Image
                        filename="products/22/banner"
                        className="rounded shadow"
                        alt="método imagen 3"
                    />
                </div>
    </div>*/}

            {/** Cómo adquirirlo */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
